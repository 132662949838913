import CatalogCategory from "./CatalogCategory"
import CatalogCertificate from "./CatalogCertificate"
import CatalogCertificatePreview from "./CatalogCertificatePreview"
import Category from "./Category"
import Certificate from "./Certificate"
import ContentCard from "./ContentCard"
import Course from "./Course"
import CourseCertificate from "./CourseCertificate"
import CourseEditor from "./CourseEditor"
import CourseSubscription from "./CourseSubscription"
import CourseSubscriptionBatch from "./CourseSubscriptionBatch"
import CourseSupply from "./CourseSupply"
import CpfAvailability from "./CpfAvailability"
import DataAccessLog from "./DataAccessLog"
import FormSelectionProcessSubscription from "./FormSelectionProcessSubscription"
import OrganizationSection from "./OrganizationSection"
import PermittedCourses from "./PermittedCourses"
import Registration from "./Registration"
import ReportCoursePersonalDataAccess from "./ReportCoursePersonalDataAccess"
import ReportSelectionProcessConfig from "./ReportSelectionProcessConfig"
import ReportSelectionProcessConfigSelectionProcess from "./ReportSelectionProcessConfigSelectionProcess"
import ReportUser from "./ReportUser"
import ReportUserDataExport from "./ReportUserDataExport"
import Role from "./Role"
import SearchCategory from "./SearchCategory"
import SearchCertificate from "./SearchCertificate"
import SearchOwner from "./SearchOwner"
import Section from "./Section"
import SectionContent from "./SectionContent"
import SelectionProcess from "./SelectionProcess"
import SelectionProcessDashboard from "./SelectionProcessDashboard"
import SelectionProcessEnrolmentPhase from "./SelectionProcessEnrolmentPhase"
import SelectionProcessPhase from "./SelectionProcessPhase"
import SelectionProcessPhaseUserSensitive from "./SelectionProcessPhaseUserSensitive"
import SubscriptionEvent from "./SubscriptionEvent"
import User from "./User"
import UserConfirmation from "./UserConfirmation"
import UserEmailChange from "./UserEmailChange"
import UserEmailChangeEvent from "./UserEmailChangeEvent"
import UserOrganization from "./UserOrganization"
import UserPassword from "./UserPassword"
import UserResendConfirmation from "./UserResendConfirmation"
import UserSensitiveData from "./UserSensitiveData"

const endpoints = /** @type {const} */ ({
  CatalogCategory,
  CatalogCertificate,
  CatalogCertificatePreview,
  Category,
  Certificate,
  ContentCard,
  Course,
  CourseCertificate,
  CourseEditor,
  CourseSubscription,
  CourseSubscriptionBatch,
  CourseSupply,
  CpfAvailability,
  DataAccessLog,
  FormSelectionProcessSubscription,
  OrganizationSection,
  PermittedCourses,
  Registration,
  ReportCoursePersonalDataAccess,
  ReportSelectionProcessConfig,
  ReportSelectionProcessConfigSelectionProcess,
  ReportUser,
  ReportUserDataExport,
  Role,
  SearchCategory,
  SearchCertificate,
  SearchOwner,
  Section,
  SectionContent,
  SelectionProcess,
  SelectionProcessDashboard,
  SelectionProcessEnrolmentPhase,
  SelectionProcessPhase,
  SelectionProcessPhaseUserSensitive,
  SubscriptionEvent,
  User,
  UserConfirmation,
  UserOrganization,
  UserEmailChange,
  UserEmailChangeEvent,
  UserPassword,
  UserResendConfirmation,
  UserSensitiveData,
})

export default endpoints
